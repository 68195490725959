/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

body {
    &#index{
        .carousel-home{
            .slick-arrow {
                display: none!important;
            }
        }
        .slick-arrow {
            z-index: 2;
            &.slick-next{
                padding: 0px;
                right: -50px;
                @media (max-width: 540px) {
                    right: -40px;
                }
            }
            &.slick-prev{
                padding: 0px;
                left: -50px;
                @media (max-width: 540px) {
                    left: -40px;
                }
            }
            > img{
                padding: 0 1rem;
                margin-bottom: 100px;
            }
        }
    }
    &#category{
        .sub_categories_slider {
            .slick-arrow{
                padding: 0;
                &.slick-prev{
                    left: 5px;
                }
                &.slick-next{
                    right: 5px;
                }
                > img {
                    margin-bottom: 30px;
                    background: white;
                    padding: 0;
                }
                &.slick-disabled{
                    opacity: 0.2;
                }
            }
        }
    }
}

#product-modal{
    .slick-arrow{
        img{
            padding: 0.5rem 1rem!important;
            background: $white;
            border: 0;
            border-radius: 80px;
        }
    }
}
