.carousel a {
  display: block;
  &:not(:first-child) {
    display: none;
  }
}
.slider-caption{
  position: absolute;
  top:10%;
  left: 90px;
  margin-bottom: 0;
  color: $body-color;
  max-width: 340px;
}

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none!important;
}
.slick-slide figure{
  position: absolute;
  top: 0;
}

//.carousel {
//  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
//  margin-bottom: 1.5rem;
//  .direction {
//    z-index: auto;
//  }
//  .carousel-inner {
//    height: 340px;
//    @media (max-width: 767px) {
//      height: auto;
//    }
//  }
//  .carousel-item {
//    height: 100%;
//    img {
//      @media (max-width: 767px) {
//        max-width: 100%;
//        height: auto;
//      }
//      @media (min-width: 768px) {
//      width: 100%;
//      margin-left: 0;
//    }
//    }
//    .caption {
//      position: absolute;
//      @media (min-width: 768px) {
//      bottom: 28px;
//      left: 90px;
//      }
//      @media (max-width: 767px) {
//        bottom: 5px;
//        left: 40px;
//      }
//      color: white;
//      max-width: 340px;
//      .caption-description p {
//        color: white;
//      }
//    }
//    figure {
//      @media (max-width: 767px) {
//        margin: 0;
//      }
//    }
//  }
//  .carousel-control {
//    opacity: 1;
//    .icon-next,
//    .icon-prev {
//      &::before {
//        content: "";
//      }
//      i {
//        font-size: 3.125rem;
//        color: white;
//      }
//      &:hover {
//        i {
//          color: $brand-primary;
//        }
//      }
//    }
//    .icon-prev {
//      left: 1rem;
//    }
//    .icon-next {
//      right: 2rem;
//    }
//    &.left,
//    &.right {
//      background: none;
//    }
//  }
//}
