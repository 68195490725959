.slider-caption {
  top: 0!important;
  bottom: inherit!important;
  left: 0!important;
  max-width: 100%!important;
  height: 100%!important;
  display: flex;
  align-items: center;
  .primary-title{
    padding: 3rem 2rem 3rem 3rem;
    border: 0;
    max-width: 100%;
    width: 482px;
    h1{
      font-size:3.8vh;
    }
    .caption-description{
      p{
        font-size:1.375rem;

      }
    }
  }
}

.adtm_menu_container.container{
  max-width: 1300px;
}

.cb {
  &.displayHome{
    .custom-html{
      margin-top: 1rem;
      display: block;
    }
    .block_a_la_une {
      .custom-html {
        h4{
          a {
            color: $body-color;
          }
        }
        p{
          color: $primary;
          span {
            color: $text-muted;
          }
        }
      }
    }
  }
  &.displayCustomBanners1{
    .six-bricks{
      flex-wrap: wrap;
      .html-over {
        .custom-html {
          overflow: hidden;
          display: flex;
          margin: auto;
          position: absolute;
          width: 100%;
          top: inherit;
          left: 0;
          bottom: 0;
          right: inherit;
          transition: .4s ease;
          justify-content: center;
          p{
            padding: 1rem;
            text-align: center;
            width: 80%;
          }
        }
        &:hover{
          .custom-html {
            p{
              background: $white;
            }
          }
        }
      }
    }
    .custom-html{
      margin-top: 1rem;
    }
  }
  &.displayCustomBanners3{
    .custom-html{
      margin-top: 1rem;
      display: block;
    }
  }
  &.displayCustomBanners2{
    img{
      padding-bottom: 1rem;
    }
    .custom-html{
      p{
        margin-bottom: 0px;
      }
    }
  }
}

#mobile_top_menu_wrapper {

  .modal-header{
    h3{
      line-height: 2rem;
      font-size: 18px;
    }
    i{
      line-height: 2rem;
      margin-left: 0.5rem;
    }
  }

  #_mobile_top_menu {

    #adtm_menu ul li.li-niveau1 {
      &:not(:first-child){
        a{
          width: 100%;
          border: 0;
          &:focus{
            .advtm_menu_span{
              background: $light;
            }
          }
        }
      }
      a.advtm_menu_actif{
        .advtm_menu_span{
          background: $light;
        }
      }
      .advtm_menu_span{
        padding: 0.2rem 1.5rem;
        text-transform: inherit;
      }
      @media (max-width: 767px) {
        display: flex!important;
      }
    }
  }

}
.footer-container {
  .col-lg-3 {
    @media(min-width: 992px) {
    flex: 0 0 calc((100% - 33.33%) / 3);
    max-width: calc((100% - 33.33%) / 3);
    }
  }
}

