@media (max-width: 1140px) {

  #_desktop_top_menu{
    #adtm_menu  {
      .li-niveau1 {
        a.a-niveau1 {
          .advtm_menu_span {
            max-width: 90%!important;
          }
        }
      }
    }
  }
  
}

@media (max-width: 991px) {
  #adtm_menu ul#menu {
    flex-direction: column;
  }

  .sort-by-row {
    > div {
      width: 100%;
      label{
        display: none;
      }
    }
  }

  .custombanners {
    &.displayHome {
      .banner-item {
        .big-title {
          font-size: 6vw;
        }
        .small-title {
          font-size: 3.5vw;
        }
        &.block_a_la_une{
          .custom-html{
            h4{
              width: auto;
            }
            p{
              span{
              }
            }
          }
        }
      }
    }
  }

}

@media (max-width: 860px) {
  #carousel{
    .rc{
      min-height: 500px;
      > * {
        width: initial;
      }
    }
    .slider-caption {
      left: 0 !important;
      .primary-title{
        width: 100%;
        border-radius: 0;
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 768px) {

  .blockcart{
    margin-right: 1rem;
  }
}

