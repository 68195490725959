/* muli-200normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
          local('Muli Extra Light '),
          local('Muli-Extra Light'),
          url('~typeface-muli/files/muli-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* muli-200italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
          local('Muli Extra Light italic'),
          local('Muli-Extra Lightitalic'),
          url('~typeface-muli/files/muli-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-300normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
          local('Muli Light '),
          local('Muli-Light'),
          url('~typeface-muli/files/muli-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* muli-300italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
          local('Muli Light italic'),
          local('Muli-Lightitalic'),
          url('~typeface-muli/files/muli-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-400normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
          local('Muli Regular '),
          local('Muli-Regular'),
          url('~typeface-muli/files/muli-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* muli-400italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
          local('Muli Regular italic'),
          local('Muli-Regularitalic'),
          url('~typeface-muli/files/muli-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-500normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
          local('Muli Medium '),
          local('Muli-Medium'),
          url('~typeface-muli/files/muli-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* muli-500italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
          local('Muli Medium italic'),
          local('Muli-Mediumitalic'),
          url('~typeface-muli/files/muli-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-600normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
          local('Muli SemiBold '),
          local('Muli-SemiBold'),
          url('~typeface-muli/files/muli-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* muli-600italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
          local('Muli SemiBold italic'),
          local('Muli-SemiBolditalic'),
          url('~typeface-muli/files/muli-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-700normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
          local('Muli Bold '),
          local('Muli-Bold'),
          url('~typeface-muli/files/muli-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* muli-700italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
          local('Muli Bold italic'),
          local('Muli-Bolditalic'),
          url('~typeface-muli/files/muli-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-800normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
          local('Muli ExtraBold '),
          local('Muli-ExtraBold'),
          url('~typeface-muli/files/muli-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* muli-800italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
          local('Muli ExtraBold italic'),
          local('Muli-ExtraBolditalic'),
          url('~typeface-muli/files/muli-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-900normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
          local('Muli Black '),
          local('Muli-Black'),
          url('~typeface-muli/files/muli-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* muli-900italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
          local('Muli Black italic'),
          local('Muli-Blackitalic'),
          url('~typeface-muli/files/muli-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('~typeface-muli/files/muli-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-base*$line-height-base;  /* Preferred icon size */
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
//fix height
.material-icons + span{
  display: inline-block;
}
.small .material-icons,
.btn-sm .material-icons{
  font-size: $small-font-size;
  line-height: $line-height-base;
}
.btn-lg .material-icons{
  font-size: $font-size-lg*$btn-line-height-lg;
}

.pagination .material-icons{
  font-size: $font-size-base*$pagination-line-height;
}
.material-icons.md-18,
{ font-size: 18px; line-height: 1}
.material-icons.md-24,
{ font-size: 24px; line-height: 1}
.material-icons.md-36,
{ font-size: 36px; line-height: 1}
.material-icons.md-48,
{ font-size: 48px; line-height: 1}
