.reassurance_shipping_bloc{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  padding-bottom: 1rem;
  .blockreassurance_product{
    margin-top: 0;
  }
  > div{
    @media (max-width: 540px) {
      padding: 0;
    }
    > * {
      margin-bottom: 0.5rem;
    }
    width: 100%;
    padding: 0;
    font-size: $small-font-size;

    .blockreassurance_product .item-product {
      display: inline-block;
      height: 35px;
      float: left;
      margin-right: 1rem;
      padding-top: 5px;
      width: 35px;
    }
  }
}

.product-manufacturer{
  @media (max-width: 767px){
    > div{
      flex: 0 0 100%;
    }
    > span {
      display: none;
    }
  }
}

.products-imagescover{
  img{
    width: 100%;
  }
}

.product-tabs{
  border: 0;
  ul{
    overflow: initial;
    margin-bottom: 0rem;
    border: 0;
    li{
      &:not(:last-child){
        margin-right: 0rem;
      }
      a{
        @extend .btn;
        background: $light;
        //color: $text-muted;
        border: 1px solid $light;
        &.active{
          //border: 1px solid $primary;
          background-color: $primary;
          color: $white;
        }

      }
    }
  }
  .tab-content{
    @media (min-width: 450px) {
      padding: 2rem;
    }
    padding: 1rem;
    @extend .border;
  }
}

.availability_info_product{
  > span {
    font-size: 13px;
    font-style: italic;
    i{
      font-size: 19px;
    }
  }
}

.multiple_payments{
  border-color: $text-muted!important;
  display: block;
  float: left;
  margin: 0 0 3rem 0;
  .card-body{
    padding: 0.8rem 1.5rem;
    .single_payment {
      font-weight: 700;
      color: $tertiary;
      font-size: 1.5rem;
      span{
        font-size: 1.125rem;
      }
    }
  }
}

#blockcart-modal{
  .modal-header{
    background: $primary;
    h3{
      color: $white;
    }
    button{
      i{
        color: white;
      }
    }
  }
  .media-body{
    p{

      font-size: $small-font-size;
      margin-bottom: 0;
      &.product-price{
        margin-bottom: 0.5rem;
        color: $tertiary;
      }
    }
  }
}

.product-accessories{
  .h2{
    font-size: $h1-font-size;
  }
}

#product {
  .product-criterion {
    align-items: center;
    @media (max-width: 540px) {
      justify-content: center;
    }
  }
  h1{
    @media (max-width: 992px) {
      margin-top: 2rem;
    }
  }
  .js-qv-product-images{

    width: 100%;
    height: 100%;
    .draggable{
      height: 350px;
      .slick-slide{
        .product-thumb{
          margin: 0;
          img{

          }
        }
      }
    }
    .slick-current {
      border-color: transparent;
      .product-thumb{
        border:2px solid $dark;
      }
      .rc {
        &:before {
          //content: '';
          //position: absolute;
          //height: 100%;
          //width: 100%;
          //top: 0;
          //left: 0px;
          //border: 3px solid $dark;
          //z-index: 9;
        }
      }
    }

    .product-thumb{
      height: auto;
      .rc{
        height: auto;
        padding-top: 75%;
        img{
          height: auto;
        }
      }
    }
    .slick-track{
        //height: 350px;
    }
  }
  .btn-zoom{
    @media (min-width: 992px) {
      display: block;
    }
    display: none;
  }
  .products-imagescover{
    .rc{
      padding-top: 75%;
    }
  }
  #product-modal{
    .rc{
      padding-top: 75%;
      > * {
        height: auto;
      }
    }
  }
}

[id^="product-description-short"] {
  height: 27px;
  overflow: hidden;
  &.expanded{
    height: auto;
  }
}

.product-flags{
  li{
    &.mieux_note{
      background: $etiquetteMieuxNote;
    }
    &.top_vente{
      background: $etiquetteTopVente;
    }
    &.coup_coeur{
      background: $etiquetteCdc;
    }
  }
}