body{
  background-color: $wrapper-bg;
}
a{
  text-decoration: none;
  &:hover{
    color: $primary;
  }
}
sup{
  top:-0.3em;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }

}
.page-wrapper,.card-block{
  @extend .card;

}
.page-header{
  @extend .card-header;
  h1{
    margin-bottom: 0;
  }
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin
{
  box-shadow: $bs-touchspin-boxshadow;
  border:$input-border-width solid $input-border-color;
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0,0,0,0);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
  display: block;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  border: 1px solid $gray-300;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
  .carrier-price{
    font-weight: bold;
    font-size: $font-size-lg;
    color: $primary;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}

//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
  .nav-item{
    margin-bottom: 0rem;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form{
    width: 75%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}

/**********************************************************/
/******************* Custom Styles ************************/
/**********************************************************/

//general styles
body {
  color: $body-color;
}

h1{
  text-transform: inherit;
}

h3 {
  color: $tertiary;
  text-transform: uppercase;
}

h2{
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  &.products-section-title{
    text-transform: inherit;
    font-size: 1.875rem;
  }
}

p{
  font-size: .9375rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, .products-section-title, h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.verysmall{
  font-size: 0.75rem;
}

body#my-account{
  h1{
    text-align: center;
    margin: 2.5rem 0;
  }
}

// buttons
.btn {
  font-size: 1.125rem ;
  &:not(.js-touchspin){
    font-weight: 600;
    //border-radius: $border-radius-50;
    //color: $white;
  }
  &:focus{
    outline: 0;
    box-shadow: none;
  }
  &.btn-secondary{
    //font-family: ;
    letter-spacing: 1px;
    color: $black;
    background: transparent;
    text-decoration: underline;
    border: 0;
    text-transform: uppercase;
    &:hover{
      background: transparent;
      color: $gray-light;
    }
    &:focus, &:active, &:not(:disabled), &:not(:disabled):active, &:not(:disabled):active:focus{
      outline: 0;
      background: none;
      color: $dark;
      border: 0;
    }
  }
  &.btn-tertiary{
    text-transform: uppercase;
    background: $primary;
    color: $white;
    &:hover{
      background: rgba($primary, 0.8);
      border-color: rgba($primary, 0.8);
    }
    &:disabled{
      color: white;
    }
    &:focus{
      outline: 0;
    }
    &[name = 'submitNewsletter']{
      border-radius: 0px!important;
      &:disabled{
        opacity: 1;
      }
    }
  }
  &.btn-primary{
    color: $white;
    &:hover{
      background: rgba($primary, 0.8);
      border-color: rgba($primary, 0.8);
    }

    &:disabled{
      color: white;
    }
    &:focus{
      outline: 0;
      box-shadow: none;
    }
  }
  &.btn-link{

  }
  &.btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &.search-widget__btn{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding: 0.55rem 1rem;
    i{
      font-size: 1.8rem;
    }
  }
  &.btn-outline-primary{
      border-radius: 0;
      //font-size: .75rem;
      //padding: 0.3rem 0.7rem!important;
      //border-color: $gray-light;
      //background: $light;
      &:hover {
        color: $white;
        background: $primary;
        border-color: $primary;
      }
    }
  &.btn-outline-primary, &.btn-outline-secondary{
      i{
        font-size: 0.8rem;
        margin-top: 3px;
        margin-left: .3rem;
      }
  }
  &.btn-add-to-cart{
    text-transform: initial;
  }
}

.btn[data-action="show-password"]{
  border-radius: 0;
  padding: 0.63rem;
}

// inputs
.form-control{
  &input{
    &:focus{
      box-shadow: none!important;
      outline: 0;
    }
  }
}

//newsletter
.block_newsletter{
  .newletter_title{
    text-transform: inherit!important;
    font-size: 1.375rem;
    color: $black;
  }
  input{
    padding: 0 1rem;
    height: 3.7rem;
    &::placeholder{
      color: $gray-light;
    }
    &:focus{
      box-shadow: none;
      outline: 0;
    }
  }
  button{;
    padding: 0.9rem 1rem;
  }
}

//header
.shopping-cart {
  position: relative;
  .blockcart__icon {
    font-size: 1.8rem;
  }
  .cart-products-count {
    position: absolute;
    background: $primary;
    padding: 0 5px;
    border: 0;
    border-radius: 10px;
    color: $white;
    font-size: .68rem;
    left: 17px;
    top: -8px;
  }
}

.account-icon{
  font-size: 2rem;
}

.user-info{
  .account {
    span{
      & + span{
        margin-left: 0.5rem;
        max-width: 70%;
        line-height: 1;
      }
    }
  }
}

//pages
#main > h1 {
  text-align: center;
  margin-top: 2rem;
}

.address-header, .card-header, .page-header , .breadcrumb, .page-footer{
  background: transparent;
  border: 0;
  > h1{
    text-align: center;
    margin-top: 2rem;
  }
}

#checkout, #cart  {
  h1 {
    margin-top: 0;
    padding: 1.5rem;
    background: $gray-100;
    text-align: left;
    font-size: $h2-font-size;
    //border-bottom: 2px solid $gray-light;
    border: 1px solid $gray-300;
  }
  .checkout-step{
    &:not(.-unreachable){
      h1{
        //box-shadow: 0 6px 10px 0 rgba(0,0,0,0.05);
        border-bottom: 2px solid $primary !important;
      }
      .step-number{
        color: $primary;
      }
    }
  }
  .checkout-form form{
    max-width: 100%;
    width: 100%;
  }
  .alert{
    li{
      text-align: center;
    }
  }
}

#cart h1{
  border-bottom: 2px solid #8ab13c !important;
}

.page-footer{
  a {
    background: $white;
    text-align: center;
    display: block;
    margin-bottom: 2rem;
  }
}

[name="qty"] {
  padding-right: 0;
  padding-left: 0;
  max-width: 40px!important;
  height: 2.8rem;
}

#cart {
  .product-line-grid-right{
    .bootstrap-touchspin{
      .js-touchspin{
        background: none;
        border: 0;
      }
    }
  }
}

.breadcrumb-container{
  border-bottom: 1px solid $gray-light;
  .breadcrumb{
    .breadcrumb-item {
      @media (max-width: 1200px) {
        font-size: $small-font-size;
      }
      &.active {
        color: $primary;
        font-weight: 700;
        &:before{
          color: $primary;
        }
      }
    }
  }
}
@media all and (-ms-high-contrast:none) {

  .logo {
    @media (max-width: 768px) {
      max-width: 200px;
    }
    max-width: 250px;
  }
}

.header__right {
  @media (min-width: 992px) and (max-width: 1200px) {
    padding-left: 30px;
    padding-right: 15px;
  }
@media (min-width: 1200px) {
  > div {
    flex: 0 0 50%;
  }
}
}

.account-links .link-item, .address, .block-categories, .card, .card-block, .left-column .search_filters, .page-wrapper {
  border: 0;
}

.page-header--cms {
  text-align: center;
}

//search widget
.search-widget__input-right{
  border-radius: $border-radius-50;
  padding-left: 1.5rem;
}

//facetes

body#category {
  #select-sort-order + .select2-container{
  margin-right: 1rem;
  }
  #_desktop_search_filters_wrapper{
    z-index: 9;
    & + div {
      .select2-container--open{
        .select2-dropdown{
          @media(max-width: 1200px) and (min-width:992px) {
            margin-left: -97px;
          }
          @media (max-width: 992px){
            top: 0px;
          }
        }
      }
    }
  }
}

.select2-dropdown.select2-dropdown--above {
  top: 293px !important;
}

#js-active-search-filters {
  padding-left: 0;
  padding-right: 0;
  margin-bottom:0;
  padding-top: 1rem;
  .js-search-link{
    margin-right: 0.5rem;
    display: inline-flex;
    align-items: center;
    font-size: 0.75rem;
  }
}

#js-product-list {
  padding-top: 1.5rem;
}

.active_filters {

}

.js-search-filters-clear-all{
  font-size: 0.75rem;
  i{
    font-size: 0.75rem;
  }
}

// product
#product{
  h1{

  }
  .col-lg-6{
    .price, .price.current-price-discount {
      font-size: 1.875rem;
    }
    .regular-price{
      @extend .text-muted;
      font-size: 1.25rem;
    }
  }
  #content-wrapper{
    margin-top: 2rem;
  }
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// cart
.bootstrap-touchspin{
  box-shadow: none;
  border-left-width: 2px;
  border-right-width: 2px;
  border-color: $gray-light;
}

// checkout
.form__add-voucher{
  .btn-primary{
    border-radius: .25rem;
  }
}
#payment-option-4-container {
  img {
    max-width: 300px;
  }
}



#product {
  .data-sheet {
    display: table;
    > dt {
      display: table-cell;
    }
    dt, dd {
      padding: 0.5rem 0.9rem;
    }
  }
}
