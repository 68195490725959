.block-category{
  margin-top: 1.5rem;
}
.category-cover{
  img{
    width: 100%;
  }
}

#subcategories{
  .subcategory-image-container{
    a{
      overflow: hidden;
      max-width: 152px;
      width: 152px;
      display: block;
      .subcategory-image{
        width: 150%;
        margin-left: -25%;
      }
    }
  }
  .subcategory-name{
    margin-top: 0.5rem;
    max-width: 150px;
    text-align: center;
    display: block;
    font-size: 0.8rem;
    color: $black;
    &:hover{
      color: $primary;
    }
  }
}

.sort-by-row{
  z-index: 4;
}